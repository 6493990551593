import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Berufsbild`}</h1>
      <p>{`Der Heilpraktiker übt die Heilkunde berufsmäßig und eigenverantwortlich aus. Seine Tätigkeit zur Feststellung, Linderung und Heilung von Krankheiten gründet auf Vorstellungen und Verfahren aus der Tradition der Naturheilkunde, die in Diagnostik und Therapie zu allen Zeiten nach dem Ganzheitsprinzip vorging, weil sie sich an den Gesetzmäßigkeiten der Natur auch der inneren Natur des Menschen orientierte.`}</p>
      <p>{`Damit ist die Naturheilkunde des Heilpraktikers grundsätzlich unabhängig von Zeitströmungen, Systemzwängen oder dem jeweils herrschenden Wissenschaftsbild, wiewohl der Heilpraktiker wissenschaftlich gesicherte Forschungsergebnisse und Erkenntnisse in seiner Tätigkeit selbstverständlich berücksichtigt.`}</p>
      <h2 {...{
        "id": "die-heilkunde-des-heilpraktikers"
      }}>{`Die Heilkunde des Heilpraktikers`}</h2>
      <p>{`Die Naturheilkunde basiert auf einem Naturbegriff, der sich in der Tatsache des Lebens äußert. Die möglichst vollkommene Erhaltung des Lebens durch sich selbst organisierende Systeme und Ordnungsprinzipien scheint dem Heilpraktiker das Endziel der Schöpfung zu sein. Der Respekt vor diesen als sinnvoll anerkannten Gesamtzusammenhängen bestimmen sein Denken und Handeln.`}</p>
      <p>{`Er sieht die wahrnehmbaren Veränderungen bei seinen Patienten als Ausdruck wechselnder äußerer und innerer Bedingungen und Ursachen und versucht, diese ganzheitlich in ihren Gesamtzusammen- hängen zu erfassen, sie nach den Kriterien seines naturheilkundlichen Modells zu bewerten und zu ordnen. Er wird den Patienten im Sinne der Ordnungstherapie über die Zusammenhänge seines Leidens aufklären, dessen Erkenntnis hierüber fördern und ihn nach dem naturheilkundlichen Modell (lege artis) therapieren.`}</p>
      <p>{`Gesundheit ist für den Heilpraktiker die Bewahrung der Integrität einer Persönlichkeit in seiner Geist-Körper-Seele-Einheit. Die Selbstheilungskräfte sind keine speziellen oder spezifischen Kräfte, sondern Ausdruck der allgemeinen Heilkraft der Natur, die in der Lebenskraft einer Persönlichkeit begründet sind. Die Lebenskraft ist das Wirken eines mehr oder weniger einverständlichen und harmo- nischen Zusammenspiels aller organischen Strukturen, Funktionen, Energien, Informationen, geistiger und seelischer Kräfte. Im Gegensatz zur eindeutig bestimmbaren Quantität ist die Lebenskraft ein Qualitätsbegriff. Deren Eigenschaften lassen sich nur durch das Verhalten einer Gesamtpersönlichkeit beurteilen. Ein ganzheitliches System besitzt grundsätzlich Funktionseigenschaften, die keines seiner Teile besitzt und die nur durch die Vernetzung seiner Elemente und Elementarprozesse erklärbar sind.`}</p>
      <p>{`Die Stabilität und Integrität eines biologisch ganzheitlichen Systems ist so geschützt und abgesichert, daß eine Ursache allein diese in der Regel nicht zu stören vermag, hierzu bedarf es eines gewissen Komplexes und Spektrums von verursachenden und störenden Bedingungen, die von dem ganz- heitlichen System nicht mehr kompensiert werden können und zu Störungen der Gesundheit und Krankheit führen. Die Naturheiltherapie zielt deshalb darauf ab, durch die Beseitigung der Bedingungen, die eine Krankheit hervorrufen, unterhalten und fördern, dem gestörten biologischen System die Möglichkeit zu geben durch Selbstorganisation die Gesundheit wiederherzustellen.`}</p>
      <p>{`Der Heilpraktiker stellt deshalb nicht nur die Krankheit als einen objektiven Tatbestand fest, sondern richtet sein Augenmerk auch auf die Gesamtperson des Kranken, die für die Erkenntnisse über den Krankheitsverlauf auch prognostisch in den Mittelpunkt der Betrachtung rückt.`}</p>
      <p>{`Neben anamnestischen und klinischen Daten zieht der Heilpraktiker zur Erkenntnisgewinnung der gestörten Integrität eines Kranken deshalb die Beurteilung von Konstitution, Temperament, Disposition und Diathese mit heran. Dabei kommen die typischen qualitativ beurteilenden naturheilkundlichen Diagnoseverfahren, wie z.B. die Augendiagnose, die Pulsdiagnose, oder auch bioenergetische Verfahren zum Einsatz.`}</p>
      <p>{`Seine daraus folgenden therapeutischen Überlegungen zielen auf das Begünstigen der Selbstheilungs- kräfte zu einem natürlichen Heilverlauf,`}</p>
      <ul>
        <li parentName="ul">{`ob es um eine Entlastung und Entgiftung des Organismus durch Aus und Ableitungsmethoden geht, ob es durch Simulieren von Störungen, die durch Übung zu einer verbesserten Anpassung konditionieren sollen, geschieht, wie in der Homöopathie oder Hydrotherapie`}</li>
        <li parentName="ul">{`ob es durch gezielte Erregung von Kompensationssystemen geschieht, wie in der Humoralpathologie und Physiotherapie,`}</li>
        <li parentName="ul">{`ob mit der Behandlung durch eine ähnlich biologische Systematik auf die ebenso biologischen Entsprechungen und Ähnlichkeiten im Menschen abgezielt wird, wie es über die reine Stofflichkeit hinaus bei der Phytotherapie, Biochemie und Spagyrik geschieht,`}</li>
        <li parentName="ul">{`ob eine Schonung der Systeme das Ziel der Therapie ist, wie bei einer speziellen Diät oder Lebensweise,`}</li>
        <li parentName="ul">{`ob es um die nach einer Konstitutionsdiagnose erkannten Möglichkeiten in bezug auf die Entwicklung potentieller genetischer Möglichkeiten geht, wie in der Verhaltens-, Sozio- oder Psychotherapie, oder`}</li>
        <li parentName="ul">{`ob es um die vielen weiteren Formen der Anwendung regulatorisch-naturheilkundlicher Therapie- und Arzneianwendungen mineralischer, pflanzlicher oder tierischer Herkunft geht.
Der Heilpraktiker regt bei seiner Behandlung stets die natürlichen Selbstheilungskräfte an. Der Vielfältigkeit neuer diagnostischer und therapeutischer Möglichkeiten, die diesem Ziel dienen, und Eingang in das Behandlungsspektrum des Heilpraktikers finden können, sind auch in der Zukunft keine Grenzen gesetzt. Entscheidend ist allein, ob Verfahren der Theorie der Naturheilkunde entsprechen und Iege artis” nach den Regeln der Kunst angewendet werden können.`}</li>
      </ul>
      <h2 {...{
        "id": "das-verhaltnis-zum-patienten"
      }}>{`Das Verhältnis zum Patienten`}</h2>
      <p>{`Da der Kranke in seiner ganzheitlichen Persönlichkeit im Mittelpunkt naturheilkundlicher Erwägungen steht, ergibt sich zwischen Heilpraktiker und Patient ein Verhältnis, das von gegenseitiger Achtung und gegenseitigem Respekt geprägt ist. Der Heilpraktiker sieht sich weniger als Fachautorität für Krankheitsreparatur, vielmehr als Mitmensch des Hilfesuchenden. Der Heilpraktiker geht davon aus, daß der Patient, der zu ihm findet, wesentliche Merkmale seiner Krankheit mitbringt: Erscheinung, Gang, Gestik, Sprache, Dynamik, Geruch und natürlich die berichteten Symptome des Befindens.`}</p>
      <p>{`Der Heilpraktiker öffnet sich dem Patienten, nimmt ihn an, hört ihm zu. Er verknüpft und strukturiert seine Beobachtungen und das Berichtete mit seinen Erfahrungsmustern, um einerseits daraus sein therapeutisches Vorgehen abzuleiten und andererseits den Patienten über die Zusammenhänge seiner Probleme aufzuklären sowie für eine aktive und mitverantwortliche Unterstützung bei den therapeutischen Bemühungen zu gewinnen. Das Verhalten des Heilpraktikers seinem Patienten gegenüber ist von fachlichem und mitmenschlichen Engagement geprägt mit dem Ziel, den Patienten zu ebensolchem Engagement anzuregen für eine gesunde, vernünftige und den erkannten Krankheiten, Konstitutionsschwächen angemessene Lebensführung im Sinne einer Ordnungstherapie.`}</p>
      <p>{`In einem durch langjährige Erfahrungen entstandenen Vertrauen ist es das Ziel des Heilpraktikers ein so persönliches Verhältnis zu seinem Patienten aufzubauen und ihn so genau kennenzulernen, daß er der Verantwortung, bei allen Problemen gestörter Integrität, Ratgeber sein zu können, guten Gewissens gerecht wird. Der Heilpraktiker empfindet sich als geeigneter Ansprechpartner und sinnvolle Ergänzung eines aufgeklärten und für seine Gesundheit mitverantwortlichen Bürgers in unserer Gesellschaft.`}</p>
      <h2 {...{
        "id": "aufgaben"
      }}>{`Aufgaben`}</h2>
      <p>{`Der Heilpraktiker hat in erster Linie die Aufgabe, die individuellen gesundheitlichen Bedürfnisse der Bürger, über das Angebot der offiziellen medizinischen Bedarfsdeckung des Gesundheitswesens hinaus, ergänzend und alternativ zu erfüllen.`}</p>
      <p>{`Damit erfüllt er auch eine gesellschaftliche Aufgabe: Er verhindert in den ihm eigenen Bereichen gesundheitlicher Versorgung eine unserer demokratisch pluralistischen Gesellschaft unangemessene Monopolstellung der institutionalisierten Medizin und bildet praktisch eine Regulativfunktion, in dem durch sein Wirken nicht nur die Therapiefreiheit sinnvoll gewahrt wird, sondern auch die Wahlfreiheit des Bürgers nach einem von ihm persönlich bevorzugten Therapeuten.`}</p>
      <p>{`Diese soziologische Funktion erfüllt der Heilpraktiker als eigenständiger Behandler unabhängig davon, ob einige seiner Therapien die wissenschaftliche Anerkennung erlangen und/oder Eingang in die allgemeine Medizin finden. Außerdem vervollständigt er das Spektrum naturheilkundlicher Verfahren über evtl. auch von der wissenschaftlichen Medizin übernommener Methoden hinaus und leistet mit diesem Angebot wiederum einen unverzichtbaren Beitrag zur Therapiefreiheit und Therapievielfalt.`}</p>
      <p>{`Darüber hinaus erfüllt der Heilpraktiker durch seine ihm eigene Art der Heilkunde auch Aufgaben für die Volksgesundheit, in dem er seine Patienten grundsätzlich zu einer gesunden Lebensweise, speziell im Bereich der Ernährung, anhält.`}</p>
      <p>{`Weiterhin ist ihm, besonders in Zeiten wissenschaftlichen Dogmatismus der Medizin, die Aufgabe zugefallen, Bewahrer der traditionellen und reinen Naturheilkunde zu sein. Der Heilpraktiker hat die Pflege der Tradition dieses wichtigen Kulturgutes unseres Volkes übernommen und hält dieses bis auf den heutigen Tag in Theorie und Praxis lebendig, besonders in Bereichen, die von der offiziellen Medizin dogmatisch verdrängt oder ignoriert werden.`}</p>
      <h2 {...{
        "id": "entwicklung-und-geschichte"
      }}>{`Entwicklung und Geschichte`}</h2>
      <p>{`Schon immer hat es Menschen gegeben, die bei erkrankten Mitmenschen erfolgreicher Hilfe leisten konnten als andere, ein Umstand, den man ihrer natürlichen Heilbegabung zuschrieb. Sie gilt, zusätzlich zu Erlernbarkeit und Können, bis auf den heutigen Tag als wünschenswerte Eigenschaft für den Beruf des Heilpraktikers.`}</p>
      <p>{`Heilkundige mit einer solchen Grundvoraussetzung gab es zu allen Zeiten und in allen Kulturen mit ihrem Weltbild, entsprechend der unterschiedlichen Ausprägung ihres “Medizinischen Modells”.`}</p>
      <p>{`In unserem Kulturkreis fußt die Heilkunde, auf die sich der Heilpraktiker bis heute beruft, auf den Säftelehren des griechischen Altertums, die sich im wesentlichen über das Mittelalter bis in die Humoralpathologie der Neuzeit erhalten haben.`}</p>
      <p>{`Dieses Vorstellungsmodell, in das von Beginn an die Pflanzenheilkunde integriert war, erwies sich als äußerst erfolgreich. Auch wenn es seit der Gründung von Universitäten mit der scholastischen Medizin neben den Heilbehandlern aus dem Volk die Behandlung durch universitäre Ärzte gab, gingen doch beide lange Zeit von gemeinsamen Grundvorstellungen in der Heilkunde aus. Erst mit der Anerkennung der Virchowschen Zellularpathologie im vorigen Jahrhundert gehen akademisch-ärztliche Medizin und Naturheilkundler in ihren Vorstellungen von Krankeit und Gesundheit getrennte Wege.`}</p>
      <p>{`Als Reaktion auf die neue wissenschaftliche Medizin formierte sich zum Ende des 19. Jahrhunderts auch die empirische Heilkunde neu mit dem Gebot, in ihren Heilweisen den Weg der Natur nachzuvollziehen, möglichst natürlich zu behandeln, auf jeden Fall aber nicht zu schaden.`}</p>
      <p>{`Diese Heilkunde speiste sich aus 3 Quellen:`}</p>
      <ol>
        <li parentName="ol">{`der geistig-philosophischen Bewegung des ausgehenden 18. Jahrhunderts`}</li>
        <li parentName="ol">{`der volksmedizinischen Bewegung zu Beginn des 19. Jahrhundert`}</li>
        <li parentName="ol">{`den Impulsen, die auf antikes Gedankengut zurückgriffen.
Die Homöopathie Hahnemanns, die in der wissenschaftliche Medizin praktisch keinen Stellenwert hatte, wurde von Anfang an von diesen naturheilkundliche orientierten Heilkundigen anerkannt und in ihre heilkundliche Tätigkeit integriert, was ihr wesentlich zu der heutigen Verbreitung und Popularität verhalf.`}</li>
      </ol>
      <p>{`Für die gesamte Entwicklung seit dem Mittelalter stehen beispielhaft Namen wie:`}</p>
      <p>{`Äbtissin Hildegard von Bingen als namhafteste Vertreterin der Klostermedizin, deren Heilkunde aber auch von tiefer Mystik durchdrungen war.
Paracelsus als Vertreter einer universellen und breitgefächerten heilkundlichen Tätigkeit über die Alchemie bis zur Spagyrik.
Bauer Vincenz Prießnitz als Begründer der Wasserheilkunde und Erfinder des heute noch hochgeschätzten Prießnitzwickels.
Fuhrmann Johann Schroth als Vertreter des Heilfastens und der Diäthetik mit seiner Schrothkur. Pfarrer Sebastian Kneipp, der für die Erneuerung und Erweiterung der Wasserheilkunde sowie für eine gesunde Lebensweise steht, u.a. mit seinem Grundlagenwerk “So sollt Ihr leben”.
Pastor Emanuel Felke, der wegen seiner Lehmbäder den Beinamen “Lehmpastor” erhielt. Ihn kann man in besonderer Weise wegen seiner breitgefächerten naturheilkundlichen Tätigkeit als Vater der Heilpraktiker ansehen. Seine Schwerpunkte lagen auf so heilpraktikertypischen Verfahren wie Augendiagnose, Pflanzenheilkunde und Homöopathie, aus der er erstmalig auch ein Komplexmittelsystem entwickelte.`}</p>
      <p>{`Von der Antike bis zur Neuzeit waren der freien Ausübung der Heilkunde keinerlei Grenzen gesetzt. In den breiten Bevölkerungskreisen geschah dies überwiegend durch die Heilkundigen aus dem Laienstand. Hierbei wurden die alten Methoden kontinuierlich weiterentwickelt und führten zu den neuen Verfahren des 19. Jahrhunderts.
Die Aufhebung der allgemeinen Kurierfreiheit 1851, beendete den bisherigen Rechtszustand, konnte die Weiterentwicklung jedoch icht entscheidend hemmen.`}</p>
      <p>{`Nach Wiedereinführung der Kurierfreiheit 1869 formierten sich die unterschiedlich orientierten Heilkundigen, wie z.B. Kräuterheiler, Knochenrenker, Homöopathen und Magnetopathen, kontinuierlich zu einem Berufsstand. Gemeinsame Aktivitäten in den aufkommenden Volksgesundheitsbewegungen sowie Gründung von Ausbildungsstätten und Berufsverbänden, waren ein Indiz für die endgültige Formierung eines neuen Berufsstandes.`}</p>
      <p>{`Das Heilpraktikergesetz von 1939 regelte die weitere Tätigkeit der Heilkundigen mit einer behördlichen Erlaubnis und legte die Berufsbezeichnung HEILPRAKTIKER fest. Die weitere Erlaubniserteilung konnten nur noch in besonders begründeten Ausnahmefällen erworben werden.`}</p>
      <p>{`1952 wurde diese Einschränkung, die quasi einem Ausbildungs- und Zulassungsverbot gleichkam, als mit dem Grundgesetz nicht vereinbar aufgehoben. Das Heilpraktikergesetz wurde damit die rechtliche Grundlage für die “Erlaubnis zur Ausübung der Heilkunde ohne als Arzt bestallt zu sein”.`}</p>
      <p><strong parentName="p">{`Beschlossen am 26. Juli 1995 in Düsseldorf, geändert durch Beschluß am 30. August 1996.`}</strong></p>
    </Wrapper>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      